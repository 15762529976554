import { PhoneFilterValues } from '@capturi/api-filters'
import {
  CustomerScoreParameter,
  DurationScoreParameter,
  ExternalIdentityScoreParameter,
  LabelScoreParameter,
  NotTrackerScoreParameter,
  ScoreCustomNumberPropParameter,
  ScoreCustomPropParameter,
  ScoreParameter,
  SentimentScoreParameter,
  StatusScoreParameter,
  SubjectScoreParameter,
  TeamScoreParameter,
  TrackerScoreParameter,
  UserScoreParameter,
} from '@capturi/api-scoring'
import { BaseTrackerState } from '@capturi/filters'

import { FilterValueKeys, ScoreParameterFormModel } from '../types'

const mapfromValueType: Record<
  FilterValueKeys,
  (param: ScoreParameterFormModel) => Omit<ScoreParameter, 'uid'>
> = {
  customers: (param): Omit<CustomerScoreParameter, 'uid'> => ({
    type: 'customer',
    weight: param.weight,
    customers: param.value as string[],
  }),
  customNumberProp1: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<1>, 'uid'> => ({
    type: 'custom-number-prop-1',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['customNumberProp1']),
  }),
  customNumberProp2: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<2>, 'uid'> => ({
    type: 'custom-number-prop-2',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['customNumberProp2']),
  }),
  customNumberProp3: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<3>, 'uid'> => ({
    type: 'custom-number-prop-3',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['customNumberProp3']),
  }),
  customNumberProp4: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<4>, 'uid'> => ({
    type: 'custom-number-prop-4',
    weight: param.weight,
    ...(param.value as PhoneFilterValues[`customNumberProp4`]),
  }),
  customNumberProp5: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<5>, 'uid'> => ({
    type: 'custom-number-prop-5',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['customNumberProp5']),
  }),
  customNumberProp6: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<6>, 'uid'> => ({
    type: 'custom-number-prop-6',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['customNumberProp6']),
  }),
  customNumberProp7: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<7>, 'uid'> => ({
    type: 'custom-number-prop-7',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['customNumberProp7']),
  }),
  customNumberProp8: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<8>, 'uid'> => ({
    type: 'custom-number-prop-8',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['customNumberProp8']),
  }),
  customNumberProp9: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<9>, 'uid'> => ({
    type: 'custom-number-prop-9',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['customNumberProp9']),
  }),
  customNumberProp10: (
    param,
  ): Omit<ScoreCustomNumberPropParameter<10>, 'uid'> => ({
    type: 'custom-number-prop-10',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['customNumberProp10']),
  }),
  customProp1: (param): Omit<ScoreCustomPropParameter<1>, 'uid'> => ({
    type: 'custom-prop-1',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  customProp2: (param): Omit<ScoreCustomPropParameter<2>, 'uid'> => ({
    type: 'custom-prop-2',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  customProp3: (param): Omit<ScoreCustomPropParameter<3>, 'uid'> => ({
    type: 'custom-prop-3',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  customProp4: (param): Omit<ScoreCustomPropParameter<4>, 'uid'> => ({
    type: 'custom-prop-4',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  customProp5: (param): Omit<ScoreCustomPropParameter<5>, 'uid'> => ({
    type: 'custom-prop-5',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  customProp6: (param): Omit<ScoreCustomPropParameter<6>, 'uid'> => ({
    type: 'custom-prop-6',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  customProp7: (param): Omit<ScoreCustomPropParameter<7>, 'uid'> => ({
    type: 'custom-prop-7',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  customProp8: (param): Omit<ScoreCustomPropParameter<8>, 'uid'> => ({
    type: 'custom-prop-8',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  customProp9: (param): Omit<ScoreCustomPropParameter<9>, 'uid'> => ({
    type: 'custom-prop-9',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  customProp10: (param): Omit<ScoreCustomPropParameter<10>, 'uid'> => ({
    type: 'custom-prop-10',
    weight: param.weight,
    value: (param.value as string[])[0],
  }),
  duration: (param): Omit<DurationScoreParameter, 'uid'> => ({
    type: 'duration',
    weight: param.weight,
    ...(param.value as PhoneFilterValues['duration']),
  }),
  externalIdentity: (param): Omit<ExternalIdentityScoreParameter, 'uid'> => ({
    type: 'external-identity',
    weight: param.weight,
    externalIdentity: param.value as string,
  }),
  labels: (param): Omit<LabelScoreParameter, 'uid'> => ({
    type: 'label',
    weight: param.weight,
    labels: param.value as string[],
  }),
  sentiment: (param): Omit<SentimentScoreParameter, 'uid'> => ({
    type: 'sentiment',
    weight: param.weight,
    ...(param.value as NonNullable<PhoneFilterValues['sentiment']>),
  }),
  status: (param): Omit<StatusScoreParameter, 'uid'> => ({
    type: 'status',
    weight: param.weight,
    status: param.value as string,
  }),
  subjects: (param): Omit<SubjectScoreParameter, 'uid'> => ({
    type: 'subject',
    weight: param.weight,
    subjects: param.value as string[],
  }),
  teamUids: (param): Omit<TeamScoreParameter, 'uid'> => ({
    type: 'team',
    weight: param.weight,
    teamUids: param.value as string[],
  }),
  trackers: (param): Omit<TrackerScoreParameter, 'uid'> => ({
    type: 'tracker',
    weight: param.weight,
    trackerUids: (param.value as BaseTrackerState).uids,
  }),
  notTrackers: (param): Omit<NotTrackerScoreParameter, 'uid'> => ({
    type: 'not-tracker',
    weight: param.weight,
    trackerUids: (param.value as BaseTrackerState).uids,
  }),
  userUids: (param): Omit<UserScoreParameter, 'uid'> => ({
    type: 'user',
    weight: param.weight,
    userUids: param.value as string[],
  }),
}

function createCustomNumberPropMap(
  index: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  param: ScoreParameter,
): ScoreParameterFormModel {
  const { min, max } = param as ScoreCustomNumberPropParameter<typeof index>
  return {
    filterValueType: `customNumberProp${index}`,
    weight: param.weight,
    value: { min, max },
  }
}

function createCustomPropMap(
  index: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10,
  param: ScoreParameter,
): ScoreParameterFormModel {
  return {
    filterValueType: `customProp${index}`,
    weight: param.weight,
    value: (param as ScoreCustomPropParameter<typeof index>).value,
  }
}

const mapFromModelType: Record<
  ScoreParameter['type'],
  (param: ScoreParameter) => ScoreParameterFormModel
> = {
  'external-identity': (param) => ({
    filterValueType: 'externalIdentity',
    weight: param.weight,
    value: (param as ExternalIdentityScoreParameter).externalIdentity,
  }),
  customer: (param) => ({
    filterValueType: 'customers',
    weight: param.weight,
    value: (param as CustomerScoreParameter).customers,
  }),
  'custom-number-prop-1': (param) => createCustomNumberPropMap(1, param),
  'custom-number-prop-2': (param) => createCustomNumberPropMap(2, param),
  'custom-number-prop-3': (param) => createCustomNumberPropMap(3, param),
  'custom-number-prop-4': (param) => createCustomNumberPropMap(4, param),
  'custom-number-prop-5': (param) => createCustomNumberPropMap(5, param),
  'custom-number-prop-6': (param) => createCustomNumberPropMap(6, param),
  'custom-number-prop-7': (param) => createCustomNumberPropMap(7, param),
  'custom-number-prop-8': (param) => createCustomNumberPropMap(8, param),
  'custom-number-prop-9': (param) => createCustomNumberPropMap(9, param),
  'custom-number-prop-10': (param) => createCustomNumberPropMap(10, param),
  'custom-prop-1': (param) => createCustomPropMap(1, param),
  'custom-prop-2': (param) => createCustomPropMap(2, param),
  'custom-prop-3': (param) => createCustomPropMap(3, param),
  'custom-prop-4': (param) => createCustomPropMap(4, param),
  'custom-prop-5': (param) => createCustomPropMap(5, param),
  'custom-prop-6': (param) => createCustomPropMap(6, param),
  'custom-prop-7': (param) => createCustomPropMap(7, param),
  'custom-prop-8': (param) => createCustomPropMap(8, param),
  'custom-prop-9': (param) => createCustomPropMap(9, param),
  'custom-prop-10': (param) => createCustomPropMap(10, param),
  duration: (param) => {
    const { min, max } = param as DurationScoreParameter
    return {
      filterValueType: 'duration',
      weight: param.weight,
      value: { min, max },
    }
  },
  label: (param) => ({
    filterValueType: 'labels',
    weight: param.weight,
    value: (param as LabelScoreParameter).labels,
  }),
  sentiment: (param) => {
    const { score, speaker } = param as SentimentScoreParameter
    return {
      filterValueType: 'sentiment',
      weight: param.weight,
      value: {
        score,
        speaker,
      },
    }
  },
  status: (param) => ({
    filterValueType: 'status',
    weight: param.weight,
    value: (param as StatusScoreParameter).status,
  }),
  subject: (param) => ({
    filterValueType: 'subjects',
    weight: param.weight,
    value: (param as SubjectScoreParameter).subjects,
  }),
  team: (param) => ({
    filterValueType: 'teamUids',
    weight: param.weight,
    value: (param as TeamScoreParameter).teamUids,
  }),
  tracker: (param) => ({
    filterValueType: 'trackers',
    weight: param.weight,
    value: { uids: (param as TrackerScoreParameter).trackerUids },
  }),
  'not-tracker': (param) => ({
    filterValueType: 'notTrackers',
    weight: param.weight,
    value: { uids: (param as NotTrackerScoreParameter).trackerUids },
  }),
  user: (param) => ({
    filterValueType: 'userUids',
    weight: param.weight,
    value: (param as UserScoreParameter).userUids,
  }),
}

export function toRequestModel(
  param: ScoreParameterFormModel,
): Omit<ScoreParameter, 'uid'> {
  return mapfromValueType[param.filterValueType]?.(param)
}

export function toFormModel(param: ScoreParameter): ScoreParameterFormModel {
  return mapFromModelType[param.type]?.(param)
}
