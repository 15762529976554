import {
  ButtonGroup,
  Flex,
  FlexProps,
  IconButton,
  useEditableControls,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdCheck, MdClose, MdEdit } from 'react-icons/md'

export const EditableControls: React.FC<FlexProps> = (props) => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls()

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm" {...props}>
      <IconButton
        icon={<MdCheck />}
        aria-label={t`Save changes`}
        {...getSubmitButtonProps()}
      />
      <IconButton
        icon={<MdClose />}
        aria-label={t`Cancel changes`}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center" {...props}>
      <IconButton
        size="sm"
        icon={<MdEdit />}
        aria-label={t`Edit`}
        {...getEditButtonProps()}
      />
    </Flex>
  )
}
