import { t } from '@lingui/macro'
import * as yup from 'yup'

import { MAX_PARAMETER_WEIGHT } from './constants'

export const validationSchema = yup.object().shape({
  uid: yup.string(),
  name: yup.string().required(() => t`Please add a name`),
  description: yup.string(),
  parameters: yup
    .array()
    .of(
      yup.object().shape({
        filterValueType: yup.string().required(),
        value: yup.mixed().required(() => t`Parameter must have a value`),
        weight: yup.number().positive().max(MAX_PARAMETER_WEIGHT).required(),
      }),
    )
    .required()
    .min(1, () => t`Add at least one parameter`),
})
