import { PreviewAccessRequestModel } from '@capturi/core'

const DEFAULT_API_VERSION = '3.3'

const controller = 'scores'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const scoringAPI = {
  previewAccess: (model: PreviewAccessRequestModel) => ({
    url: `${controller}/preview-access`,
    method: 'post',
    json: model,
    query: baseQuery,
  }),
}
