import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { Score, ScorePayload } from '../models/api'
import { baseUrl, cacheKey } from './constants'
import { ScoresResponse } from './useScore'

export type UpdateScorePayload = {
  uid: string
  score: Partial<ScorePayload>
}

export const useUpdateScore = (): UseMutationResult<
  Score,
  ResponseError,
  UpdateScorePayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ uid, score }: UpdateScorePayload) => {
      return request.patch<Score>(`scores/${uid}?api-version=3.3`, {
        json: score,
      })
    },
    onSuccess: (newScore) => {
      queryClient.setQueryData<ScoresResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { scores: [newScore] }
        }
        return {
          scores: oldData.scores.map((f) =>
            f.uid === newScore.uid ? newScore : f,
          ),
        }
      })
      queryClient.setQueryData<Score>([cacheKey, newScore.uid], () => {
        return newScore
      })
    },
  })
}

export const useDeleteScore = (): UseMutationResult<
  Score,
  ResponseError,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: (uid: string) =>
      request.delete<Score>(`scores/${uid}?api-version=3.3`, {}),
    onSuccess: (_, scoreUid) => {
      queryClient.setQueryData<ScoresResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { scores: [] }
        }
        return {
          scores: oldData.scores.filter((f) => f.uid !== scoreUid),
        }
      })
    },
  })
}

export const useCreateScore = (): UseMutationResult<
  Score,
  ResponseError,
  ScorePayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (score: ScorePayload) =>
      request.post<Score>(baseUrl, { json: score }),
    onSuccess: (newScore) => {
      queryClient.setQueryData<ScoresResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { scores: [newScore] }
        }
        return { scores: [newScore, ...oldData.scores] }
      })
    },
  })
}
