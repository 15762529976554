import { Fade, Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useWatch } from 'react-hook-form'

import { MAX_PARAMETER_WEIGHT } from '../constants'
import { ScoreFormModel } from '../types'

export default function IsMaxScoreMessage(): JSX.Element {
  const scoreParameters = useWatch<ScoreFormModel, 'parameters'>({
    name: 'parameters',
  })

  const isMaxScore =
    scoreParameters.reduce((total, value) => total + value.weight, 0) >
    MAX_PARAMETER_WEIGHT

  return (
    <Fade in={isMaxScore}>
      <Flex justify="right" textAlign="right" mt={4} color="primary.500">
        <Text maxW="35em" data-stonly="maximum-score-exceeded">
          <Trans>
            * Note that the total score exceeds {MAX_PARAMETER_WEIGHT}, which is
            the maximum score. The maximum score is shown as{' '}
            {MAX_PARAMETER_WEIGHT} on the analytics pages.
          </Trans>
        </Text>
      </Flex>
    </Fade>
  )
}
