import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import {
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { useCallback } from 'react'

type ScoreRange = {
  min?: number
  max?: number
}

enum Operator {
  LESS_THAN = '<',
  MORE_THAN = '>',
  RANGE = '-',
}

export const ScoreGoalInput: React.FC<{
  range: ScoreRange
  onChange: (range: ScoreRange) => void
}> = ({ range, onChange }) => {
  const operatorOptions = [
    {
      value: Operator.MORE_THAN,
      label: t`Minimum`,
    },
    {
      value: Operator.LESS_THAN,
      label: t`Maximum`,
    },
    {
      value: Operator.RANGE,
      label: t`Between`,
    },
  ]

  const [operator, setOperator] = React.useState<Operator>(() => {
    const { min, max } = range
    if (min == null && max == null) {
      return Operator.MORE_THAN
    }
    if (min != null && max == null) {
      return Operator.MORE_THAN
    }
    if (min == null && max != null) {
      return Operator.LESS_THAN
    }
    return Operator.RANGE
  })

  const handleOperatorChange = useCallback(
    (newOperator: Operator): void => {
      setOperator(newOperator)

      if (newOperator === Operator.MORE_THAN) {
        onChange({
          min: range.min ?? range.max ?? 5,
          max: undefined,
        })
      }
      if (newOperator === Operator.LESS_THAN) {
        onChange({
          min: undefined,
          max: range.max ?? range.min ?? 5,
        })
      }
      if (newOperator === Operator.RANGE) {
        onChange({
          min: range.min ?? 1,
          max: range.max ?? 10,
        })
      }
    },
    [onChange, range.max, range.min],
  )

  const showMinInput = [Operator.MORE_THAN, Operator.RANGE].includes(operator)
  const showMaxInput = [Operator.LESS_THAN, Operator.RANGE].includes(operator)
  return (
    <Stack spacing={1} isInline>
      <Box minW="8em">
        <Select
          value={operatorOptions.find((x) => x.value === operator)}
          options={operatorOptions}
          onChange={(option: OnChangeValue<SelectOption, false>) => {
            if (option == null) return
            handleOperatorChange(option.value as Operator)
          }}
          menuPortalTarget={document.body}
        />
      </Box>
      {showMinInput && (
        <Box w="5em">
          <ScoreValueInput
            value={range?.min}
            max={operator === Operator.RANGE ? range?.max : 10}
            onChange={(value) => onChange({ ...range, min: value })}
          />
        </Box>
      )}
      {showMaxInput && (
        <Box w="5em">
          <ScoreValueInput
            value={range?.max}
            min={operator === Operator.RANGE ? range?.min : 0}
            onChange={(value) => onChange({ ...range, max: value })}
          />
        </Box>
      )}
    </Stack>
  )
}

const ScoreValueInput: React.FC<{
  min?: number
  max?: number
  value?: number
  onChange?: (value: number | undefined) => void
}> = ({ min = 0, max = 10, value, onChange }) => {
  const [inputValue, setInputValue] = React.useState(value?.toString() || '')

  return (
    <NumberInput
      min={min}
      max={max}
      step={0.5}
      precision={1}
      inputMode="decimal"
      onChange={(valueAsString) => {
        setInputValue(valueAsString)
        const valueAsNumber = Number.parseFloat(valueAsString)
        onChange?.(Number.isNaN(valueAsNumber) ? undefined : valueAsNumber)
      }}
      value={inputValue}
      size="sm"
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}
