import { InaccessibleFilter, SavedPhoneFilter } from '@capturi/api-filters'
import { Score } from '@capturi/api-scoring'
import { PermissionPreset } from '@capturi/core'
import { toFilterValues } from '@capturi/filters'

import { ScoreFormModel } from '../types'

export function createFormModel(
  score?: Partial<Score>,
  savedFilter?: SavedPhoneFilter | InaccessibleFilter,
): ScoreFormModel {
  const defaultValues: ScoreFormModel = {
    name: '',
    description: '',
    segmentation: {
      channel: 'phone',
      values: {},
    },
    filters: {},
    savedFilterGroupUid: null,
    parameters: [],
    goal: null,
    permissionPreset: 'Private' as PermissionPreset,
    acl: [],
    permissionPresetUserUid: null,
    folderUid: null,
  }

  if (!score) {
    return defaultValues
  }

  const values: Partial<ScoreFormModel> = {
    uid: score.uid,
    name: score.name,
    description: score.description ?? undefined,
    segmentation: {
      channel: 'phone',
      savedFilter: savedFilter,
      values: toFilterValues(savedFilter?.values ?? {}),
    },
    filters: score.filters,
    parameters: score.parameters,
    goal:
      score.goal == null
        ? null
        : {
            min: score.goal.min ?? undefined,
            max: score.goal.max ?? undefined,
          },
    permissionPreset: score.permissionPreset ?? undefined,
    acl: score.acl ?? [],
    permissionPresetUserUid: score.permissionPresetUserUid ?? null,
    folderUid: score.folderUid ?? null,
  }
  return {
    ...defaultValues,
    ...values,
  }
}
