import { useCurrentUser } from '@capturi/core'
import { useSingleUser } from '@capturi/stores'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'

type ScoreDescriptionItemProps = {
  date: Date
  userUid?: string
  label: string
}

const ScoreDescriptionItem: React.FC<ScoreDescriptionItemProps> = ({
  userUid,
  date,
  label,
}) => {
  const { i18n } = useLingui()
  const currentUser = useCurrentUser()

  const createdBy = useSingleUser(userUid)
  const isCurrentUser = currentUser.id === userUid

  const youMessage = t`you`
  const updatedByMessage = createdBy
    ? t`${label} by ${isCurrentUser ? youMessage : createdBy.name}`
    : undefined
  const formattedTimestamp = i18n.date(date, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
  return (
    <span>
      {[updatedByMessage, formattedTimestamp].filter(Boolean).join(', ')}
    </span>
  )
}

export default ScoreDescriptionItem
