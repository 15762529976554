import { Highlight } from '@capturi/ui-components'
import { Box, Flex, Progress } from '@chakra-ui/react'
import clamp from 'lodash/clamp'
import sum from 'lodash/sum'
import React from 'react'

import { MAX_PARAMETER_WEIGHT } from '../constants'
import { ScoreFormModel, ScoreParameterFormModel } from '../types'

type Props = {
  goal: ScoreFormModel['goal']
  params: ScoreParameterFormModel[]
}

const TotalWeight: React.FC<Props> = ({ params, goal }) => {
  const totalSum = sum(params.map((x) => x.weight ?? 0))
  const totalScore = clamp(totalSum, 0, MAX_PARAMETER_WEIGHT)

  const { min, max } = goal || {}
  const left = min != null ? (min / MAX_PARAMETER_WEIGHT) * 100 : 0
  const right =
    max != null
      ? ((MAX_PARAMETER_WEIGHT - max) / MAX_PARAMETER_WEIGHT) * 100
      : 0
  return (
    <Flex direction="column">
      <Flex mt={2}>
        <Box flex="1">
          <Progress
            value={totalScore}
            min={0}
            max={MAX_PARAMETER_WEIGHT}
            colorScheme="primary"
            borderRadius="sm"
            width="full"
            h={5}
            flex={1}
            css={{
              div: {
                transition: 'all 300ms ease-out',
              },
            }}
          />
          {goal && (
            <Box mt="2" w="100%" h="3px" pos="relative">
              <Box
                pos="absolute"
                left={`${left}%`}
                right={`${right}%`}
                h="100%"
                bg="success"
                transition="all 150ms ease"
              />
            </Box>
          )}
        </Box>
        <Highlight ml={4} w={6} flex="0 0 auto" textAlign="right">
          {totalScore}
          <Box display="inline" color="primary.500" float="right">
            {MAX_PARAMETER_WEIGHT < totalSum ? ' *' : ''}
          </Box>
        </Highlight>
      </Flex>
    </Flex>
  )
}

export default TotalWeight
